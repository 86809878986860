const KEY_TOKEN = "auth_token"
const KEY_EXP_TOKEN = "exp_token"
const PRODUCT = "product"
const GROUP = "group"
const ROLE = "role"

const HEATMAP_CAMERA = "heatmap-camera";
const HEATMAP_LAYOUT = "heatmap-layout";

const ACCOUNT_TYPE_SHOPPING_MALL = "shopping_mall";
const ACCOUNT_TYPE_STORES = "stores";

export default {
    logout(link) {
        localStorage.removeItem(KEY_TOKEN);
        localStorage.removeItem("exp_token");
        if (link != "") {
            location.href = link;
        }
    },
    get_token() {
        return localStorage.getItem(KEY_TOKEN);
    },
    get_product(product) {
        var listProduct = localStorage.getItem(PRODUCT);
        return listProduct.includes(product);
    },
    get_role() {
        return localStorage.getItem(ROLE);
    },
    get_exp_token() {
        let exp = Number(localStorage.getItem(KEY_EXP_TOKEN));
        if (isNaN(exp)) exp = 0;
        return exp;
    },
    set_token(token, product, role) {
        localStorage.setItem(KEY_TOKEN, token);
        localStorage.setItem(
            KEY_EXP_TOKEN,
            Math.floor(Date.now() / 1000) + 172800
        );
        localStorage.setItem(PRODUCT, product);
        localStorage.setItem(ROLE, role);
    },
    random_int(min, max) {
        var random = Math.floor(Math.random() * (+max - +min)) + +min;
        return random;
    },
    setGroup(listGroup) {
        localStorage.setItem(GROUP, listGroup);
    },
    getGroup() {
        return localStorage.getItem(GROUP);
    },
    HEATMAP_LAYOUT: HEATMAP_LAYOUT,
    HEATMAP_CAMERA: HEATMAP_CAMERA,
    ACCOUNT_TYPE_SHOPPING_MALL: ACCOUNT_TYPE_SHOPPING_MALL,
    ACCOUNT_TYPE_STORES: ACCOUNT_TYPE_STORES,
    serializeQueryParams (obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    },
    transformTime(timeValue) {
        var secondUnit = " giây"
        var hourUnit = " giờ "
        var minuteUnit = " phút "
        if (localStorage.lang == "en") {
            secondUnit = "s"
            hourUnit = "h "
            minuteUnit = "m "
        }
        if (timeValue < 60) {
            timeValue = Math.floor(timeValue) + secondUnit
            return timeValue
        } else if (timeValue >= 60 && timeValue < 3600) {
            
            var minute = parseInt(timeValue / 60)
            minute = minute + minuteUnit

            var second = parseInt(timeValue % 60)
            if (second > 9) {
                second = second + secondUnit
            } else {
                second = "0" + second + secondUnit
            }
            timeValue = minute + second
            return timeValue
        } else if (timeValue >= 3600) {
            var hour = parseInt(timeValue / 3600)
            hour = hour + hourUnit
            var hourDivideRedundant = parseInt(timeValue % 3600)
            var minute = parseInt(hourDivideRedundant / 60)
            if (minute > 9) {
                minute = minute + minuteUnit
            } else {
                minute = "0" + minute + minuteUnit
            }
            var second = parseInt(hourDivideRedundant % 60)
            if (second > 9) {
                second = second + secondUnit
            } else {
                second = "0" + second + secondUnit
            }
            
            timeValue = hour + minute + second
            return timeValue
        }
    }
};