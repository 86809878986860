import EditProfileForm from "./Dashboard/Pages/UserProfile/EditProfileForm.vue";
import Heatmap from "./Dashboard/Heatmap.vue"
import HeatmapLayout from "./Dashboard/Pages/FaceRecognition/HeatmapLayout";


import TimeSelect from "./Components/TimeSelect/TimeSelect";
import TimeSelectTabReport from "./Components/TimeSelect/TimeSelectTabReport";

// dashboard shopingmall
import CardView from "./Components/Card/CardView.vue";
import CardSparkline from "./Components/Card/CardSparkline.vue";
import TableKPI from "./Components/Tables/Kpi.vue";
import ReportCustomer from "./Components/Tables/ReportCustomer.vue";
import ListCardForDashboardShopingmall from "./Components/Card/ListCardForDashboardShopingmall.vue"
import LineChartForDashboardShopingmall from "./Components/Charts/LineChartForDashboardShopingmall.vue"
import LineChartZoneAnalysis from "@/pages/Components/Charts/LineChartZoneAnalysis";
import StackedAreaChartTranslateZone from "@/pages/Components/Charts/StackedAreaChartTranslateZone";
import HeatmapCameraZoneAnalysis from "@/pages/Components/Heatmap/HeatmapCameraZoneAnalysis";
import StackedBarChartInteractionRate from "@/pages/Components/Charts/StackedBarChartInteractionRate";
import QuadrantChartInteractionRate from "@/pages/Components/Charts/QuadrantChartInteractionRate";
import HeatmapLayoutForDashboardShopingMall from "./Components/Heatmap/HeatmapLayoutShopingMall"

// report shopingmall
import TimeSelectShopingMall from "./Components/TimeSelect/TimeSelectShopingMall.vue";
import ListCardForReportShopingMall from "./Components/Card/ListCardForReportShopingMall";
import TreeMapForReportShoppingMall from "./Components/Charts/TreeMapForReportShoppingMall";
import HeatmapForReportShopingMall from "./Components/Charts/HeatmapForReportShopingMall";
import LineChartForReportShoppingMallYear from "./Components/Charts/LineChartForReportShoppingMall/LineChartForReportShoppingMallYear";
import LineChartForReportShoppingMallWeek from "./Components/Charts/LineChartForReportShoppingMall/LineChartForReportShoppingMallWeek";
import LineChartForReportShoppingMallMonth from "./Components/Charts/LineChartForReportShoppingMall/LineChartForReportShoppingMallMonth";
import LineChartForReportShoppingMallDay from "./Components/Charts/LineChartForReportShoppingMall/LineChartForReportShoppingMallDay";
import LineChartWrapper from "./Components/Charts/LineChartForReportShoppingMall/LineChartWrapper"

import KpiTableReport from "./Components/Tables/KpiTableReport.vue";
import ChartBehaviour from "./Components/Charts/behaviour.vue";
import OverviewSection from "./Components/SectionReportRetailStore/OverviewSection.vue"
import OverviewStoreSection from "./Components/SectionReportRetailStore/OverviewStoreSection.vue"
import HeatmapSection from "./Components/SectionReportRetailStore/HeatmapSection.vue"

import SelectMultiChoice from "./Components/SelectMultiChoice";
import DepartmentModal from "./Components/Modal/DepartmentModal";


import ChartSegmentation from "./Components/Charts/Segmentation.vue";
import CustomerAge from "./Components/Charts/CustomerAge.vue";
import CustomerGender from "./Components/Charts/CustomerGender.vue";
import CustomerRadar from "./Components/Charts/CustomerRadar.vue";
import BoxTooltip from "./Components/Charts/boxTooltip.vue";

// retail store
import FunnelChart from "./Components/Charts/FunnelChart.vue"
import CardRetailStore from "./Components/Card/CardRetailStore.vue";
import LineChartForDashboardRetailWeek from "./Components/Charts/LineChartForDashboardRetail/LineChartForDashboardRetailWeek.vue"
import LineChartForDashboardRetailDay from "./Components/Charts/LineChartForDashboardRetail/LineChartForDashboardRetailDay.vue"
import HeatmapComponentForRetail from "./Components/Heatmap/HeatmapComponentForRetail.vue"
import RetailDetailReportRadarChart from "./Components/Charts/RetailDetailReportRadarChart.vue"
import KPIRetailStore from "./Components/Tables/KPIRetailStore.vue"

// ReportShoppingmall
import SectionTwoReport from "./Components/SectionReportShoppingmall/SectionTwoReport.vue"
import SectionThreeReport from "./Components/SectionReportShoppingmall/SectionThreeReport.vue"

export {
    EditProfileForm,
    Heatmap,
    HeatmapLayout,
    TimeSelect,
    TimeSelectTabReport,

    CardView,
    TableKPI,
    CardSparkline,
    ReportCustomer,
    ListCardForDashboardShopingmall,
    LineChartForDashboardShopingmall,
    HeatmapLayoutForDashboardShopingMall,
    DepartmentModal,

    TimeSelectShopingMall,
    StackedAreaChartTranslateZone,
    StackedBarChartInteractionRate,
    QuadrantChartInteractionRate,
    LineChartZoneAnalysis,
    HeatmapCameraZoneAnalysis,

    ListCardForReportShopingMall,
    TreeMapForReportShoppingMall,
    HeatmapForReportShopingMall,
    SelectMultiChoice,
    LineChartForReportShoppingMallYear,
    LineChartForReportShoppingMallWeek,
    LineChartForReportShoppingMallMonth,
    LineChartForReportShoppingMallDay,
    LineChartWrapper,
    KpiTableReport,
    ChartBehaviour,

    ChartSegmentation,
    CustomerAge,
    CustomerGender,
    CustomerRadar,
    BoxTooltip,

    FunnelChart,
    CardRetailStore,
    LineChartForDashboardRetailWeek,
    LineChartForDashboardRetailDay,
    HeatmapComponentForRetail,
    RetailDetailReportRadarChart,
    KPIRetailStore,
    OverviewSection,
    OverviewStoreSection,
    HeatmapSection,

    SectionTwoReport,
    SectionThreeReport
};