const DashboardLayout = () => import('@/pages/Dashboard/Layout/DashboardLayout.vue')
const AuthLayout = () => import('@/pages/Dashboard/Pages/AuthLayout.vue')

// Login Pages
import Login from "@/pages/Dashboard/Pages/Login.vue";

// Dashboard pages
import Dashboard from '@/pages/Dashboard/DetailDashBoard.vue'
import DashboardShopingMall from '@/pages/Dashboard/DetailDashboardShopingMall.vue'

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import AddSubUser from "@/components/Forms/addSubUser.vue";

// Attendence
import ManageEmployeeV2 from "@/pages/Dashboard/Pages/Attendence/ManageEmployeeV2.vue";
import AttendanceManagement from "@/pages/Dashboard/Pages/Attendence/AttendanceManagement.vue";
import AddEmployee from "@/components/Forms/addEmployeeForm.vue";
import AttendanceForOneEmployee from "@/pages/Dashboard/Pages/Attendence/AttendanceForOneEmployee"

//Guest
import ManageCustomer from "@/pages/Dashboard/Pages/FaceRecognition/ManageGuestV2.vue";
import CustomerReport from "@/pages/Dashboard/Pages/FaceRecognition/CustomerReport.vue"

import ReportFaceRecognize from "@/pages/Dashboard/Pages/FaceRecognition/ReportFaceRecognizeV2.vue";
import CustomerDetail from "@/pages/Dashboard/Pages/FaceRecognition/CustomerDetail.vue";

// People Counting
// import RoutesMap from "@/pages/Dashboard/Pages/PeopleCounting/RoutemapV2";
import RoutesMap from "@/pages/Dashboard/Pages/PeopleCounting/Routemap";
import DetailReportPeopleCounting from "@/pages/Dashboard/Pages/PeopleCounting/DetailReportPeopleCounting.vue";
import DetailReportShopingMall from "@/pages/Dashboard/DetailReportShopingMall.vue";
import ZoneAnalysisShopingMall from "@/pages/Dashboard/ZoneAnalysisShopingMall.vue";

import BehaviorStatistic from "../pages/Dashboard/Pages/BehaviorStatistic/BehaviorStatistic";

import ManageUser from "@/pages/Dashboard/Pages/UserManagement/ManageUser.vue";

let manageUser = {
    path: "/user-management",
    component: DashboardLayout,
    redirect: "/user-management/manage-list-user",
    name: "Components",
    children: [{
            path: "manage-list-user",
            name: "Quản lý người dùng",
            component: ManageUser,
        },
        {
            path: "add-user",
            name: "Thêm người dùng",
            component: AddSubUser,
        },
        {
            path: "edit-user?id=:id",
            name: "Sửa thông tin người dùng",
            component: AddSubUser,
        },
    ],
};
let attendence = {
    path: "/attendence",
    component: DashboardLayout,
    redirect: "/attendence/manage-employee",
    name: "Components",
    children: [{
            path: "attendence-history",
            name: "Quản lý điểm danh",
            component: AttendanceManagement,
        },
        {
            path: "manage-employee",
            name: "Quản lý nhân viên",
            component: ManageEmployeeV2,
        },
        {
            path: "attendence-history-employee?id=:id&dateStart=:dateStart&dateEnd=:dateEnd",
            name: "Chấm công một nhân viên",
            component: AttendanceForOneEmployee,
        },
        {
            path: "add-employee",
            name: "Thêm nhân viên",
            component: AddEmployee,
        },
        {
            path: "edit-employee?id=:id",
            name: "Sửa nhân viên",
            component: AddEmployee,
        },
        {
            path: "behavior-statistics",
            name: "Thống kê hành vi",
            component: BehaviorStatistic,
        },
    ],
};

let faceRecog = {
    path: "/face-recogition",
    component: DashboardLayout,
    redirect: "/face-recogition/manage-customer",
    name: "Nhận diện khuôn mặt",
    children: [{
            path: "report-face",
            name: "Báo cáo nhận diện khách hàng",
            component: ReportFaceRecognize,
        },
        {
            path: "manage-customer",
            name: "Quản lý khách hàng",
            component: ManageCustomer,
        },
        {
            path: "customer-detail",
            name: "Thông tin khách hàng",
            component: CustomerDetail,
        },
        {
            path: "report-customer",
            name: "Báo cáo khách hàng",
            component: CustomerReport
        }
    ],
};

let peopleCounting = {
    path: "/people-counting",
    component: DashboardLayout,
    redirect: "/people-counting/detail-report-counting",
    name: "Kiểm soát lượng người ra vào",
    children: [
        {
            path: "routemap",
            name: "Routemap",
            component: RoutesMap,
        },
        {
            path: "detail-report-counting",
            name: "Báo cáo chi tiết",
            component: DetailReportPeopleCounting,
        },
        {
            path: "report-shoppingmall",
            name: "Báo cáo chi tiết shoping mall",
            component: DetailReportShopingMall,
        },
        {
            path: "zoneanalysis-shoppingmall",
            name: "Báo cáo chi tiết shoping mall",
            component: ZoneAnalysisShopingMall,
        },
    ],
};

let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [{
        path: "/login",
        name: "Login",
        component: Login,
    }],
};

var routes = []

if (localStorage.getItem('accountType') == 'stores') {
    routes = [{
            path: "/",
            redirect: "/dashboard",
            name: "Home",
        },
        attendence,
        faceRecog,
        peopleCounting,
        manageUser,
        authPages,
        {
            path: "/",
            component: DashboardLayout,
            children: [{
                    path: "dashboard",
                    name: "Trang chủ",
                    components: {
                        default: Dashboard,
                    },
                },
                {
                    path: "dashboard-shoppingmall",
                    name: "Trang chủ shoppingmall",
                    components: {
                        default: DashboardShopingMall,
                    },
                },
                {
                    path: "user",
                    name: "Thông tin người dùng",
                    components: {
                        default: User,
                    },
                },
            ],
        },
    ];
} else {
    routes = [{
            path: "/",
            redirect: "/dashboard-shoppingmall",
            name: "Home",
        },
        attendence,
        faceRecog,
        peopleCounting,
        manageUser,
        authPages,
        {
            path: "/",
            component: DashboardLayout,
            children: [{
                    path: "dashboard",
                    name: "Trang chủ",
                    components: {
                        default: Dashboard,
                    },
                },
                {
                    path: "dashboard-shoppingmall",
                    name: "Trang chủ shoppingmall",
                    components: {
                        default: DashboardShopingMall,
                    },
                },
                {
                    path: "user",
                    name: "Thông tin người dùng",
                    components: {
                        default: User,
                    },
                },
            ],
        },
    ];
}

export default routes;
